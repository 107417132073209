<template>
  <div>
    <div class="wrapper">
      <H2 style="text-align: center">门禁人脸数据采集</H2>
      <div id="img_inner"></div>
      <div class="preview-list">
        <ul>
          <li v-for="item in fileList" class="picture">
            <div v-if="isExample" class="tips">样例图</div>
            <div v-else class="tips">使用中</div>
            <img
              class="el-upload-list__item"
              id="picture"
              ref="picture"
              :src="item.pictureUrl"
              alt=""
            />
          </li>
        </ul>
      </div>
      <div>
        <div style="text-align: center; color: red">
          请上传或采集正面免冠照,露出眼睛和眉毛
        </div>
        <div style="text-align: center; color: red">图片文件支持.jpg格式</div>
        <div style="text-align: center; color: red">上传图片大小为10-200kb</div>
      </div>
      <div class="data-collect">
        <el-upload
          :file-list="fileList"
          :action="actionUrl"
          class="data-collect-upload"
          list-type="picture"
          :on-success="handleSuccess"
          :headers="headers"
          :show-file-list="false"
          :before-upload="handleBeforeUpload"
          :on-error="handleError"
        >
          <!-- <template #tip>
            <H3 class="el-upload__tip"> 请选择一张人脸图片: </H3>
          </template> -->
          <!-- <template #tip>
            <div class="el-upload__tip">
              请上传或采集正面免冠照,露出眼睛和眉毛
            </div>
          </template> -->
          <el-button type="primary" class="choose-picture">选择图片</el-button>
        </el-upload>
      </div>
      <el-dialog v-model="dialogVisible" class="preview-modal" width="70%">
        <img w-full :src="dialogImageUrl" alt="图片预览" />
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import EXIF from "exif-js";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import lodash from "lodash";
import equipment from "@/store/equipment";
import { getToken } from "@/utils/auth";
import getUuid from "@/utils/uuid";
import { queryList } from "@/api/index";
import axios from "axios";
import { listData } from "@/api/enum";
import { getPicture } from "@/api/index";
import * as imageConversion from "image-conversion";

const { proxy, ctx } = getCurrentInstance();

const tablItemNew = { feeCode: "", baseFee: 0, adjustFee: 0, new: true };

const { getAppHeight } = equipment();

const route = useRoute();

/*********state*******/
const nextIndex = ref(0);
const isExample = ref(true);
const img = ref("picture");
const companys = ref([]);
const carrierCodeOptions = ref([]);
const loadPortOptions = ref([]);
const isExternalOptions = ref([
  {
    text: "内配",
    value: "0",
  },
  {
    text: "外配",
    value: "1",
  },
]);
const headers = reactive({
  Authorization: "Bearer " + getToken(),
});
let fileList = ref([]);
let loadingInstance = ref({});
let actionUrl = ref(process.env.VUE_APP_BASE_API + "/smart/face/upload");
let dialogImageUrl = ref("");
let dialogVisible = ref(false);
const state = reactive({
  tableList: [],
  baseFeeList: [],
  list: [],
  copyList: [],
  baseItemListOptions: [],
  unitOptions: [],
  id: undefined,
});
let form = reactive({
  clientCompanyId: "",
  loadPortCode: "",
  isExternal: "",
  carrierCode: "",
});
// window.onload = function () {
//   const pic = document.getElementById("picture");
//   console.log(pic, 1111111111);
//   EXIF.getData(pic, function () {
//     //图片包含的所有信息(例：拍照方向、相机设备型号、拍摄时间、ISO 感光度、GPS 地理位置等数据。)
//     const imgAllInfo = EXIF.getAllTags(this);
//     const imgLon = EXIF.getTag(this, "GPSLongitude");
//     const imgLat = EXIF.getTag(this, "GPSLatitude");
//     console.log(imgAllInfo, 11111);
//   });
// };
/*********mounted*********/
onMounted(() => {
  proxy.$dd.biz.navigation.setTitle({
    title: "人脸图片采集",
  });
  const options = {
    text: "正在上传,请稍后...",
  };
  loadingInstance.value = ElLoading.service(options);
  getPicture()
    .then((res) => {
      // 0表示用户上传
      isExample.value = Boolean(res.data.example);
      fileList.value = [res.data] || [];
    })
    .catch((err) => {
      ElMessage({
        message: err || err.msg || "网络错误",
        type: "error",
      });
    })
    .finally(() => {
      loadingInstance.value.close();
    });
});
/*******methods*********/

const handlePreview = (file) => {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
};

const handleSuccess = (res, file) => {
  const list = lodash.cloneDeep(fileList.value);
  if (res.code === 200) {
    fileList.value = [
      {
        fileName: res.data.fileName,
        pictureUrl: `${res.data.filePath}`,
      },
    ];
    isExample.value = false;
    ElMessage({
      message: "<div style='width: 120px'>上传成功！</div>",
      type: "success",
      dangerouslyUseHTMLString: true,
    });
  } else {
    ElMessage({
      message: res.msg || res.data.msg,
      type: "error",
      // dangerouslyUseHTMLString: true,
    });
    fileList.value = list;
  }
  actionUrl.value = process.env.VUE_APP_BASE_API + "/smart/face/upload";
  loadingInstance.value.close();
};
const handleError = (error) => {
  ElMessage({
    message: "请检查图片大小或稍后再试...",
    type: "error",
  });
  actionUrl.value = process.env.VUE_APP_BASE_API + "/smart/face/upload";
  loadingInstance.value.close();
};
const handleBeforeUpload = (file) => {
  const options = {
    text: "正在上传,请稍后...",
  };
  loadingInstance.value = ElLoading.service(options);
  try {
    return new Promise((resolve, reject) => {
      EXIF.getData(file, function () {
        let degree = 0;
        const url = actionUrl.value;
        const Orientation = EXIF.getTag(this, "Orientation");
        switch (Orientation) {
          case 6:
            degree = 90;
            break;
          case 3:
            degree = 180;
            break;
          case 8:
            degree = 270;
            break;
          default:
            break;
        }
        actionUrl.value = `${url}?degree=${degree}`;
        // ElMessage({
        //   message: `<div style='width: 120px'>角度值:${degree},偏移度:${Orientation}</div>`,
        //   type: "success",
        //   dangerouslyUseHTMLString: true,
        // });
        resolve(file);
      });
      // imageConversion.compressAccurately(file, 200).then((res) => {
      //   resolve(res);
      // });
    });
  } catch (error) {
    ElMessage({
      message: error || "网络超时",
      type: "error",
    });
  }

  // const div = document.getElementById("img_inner");
};

//获取基准价格查询接口
const getQueryList = async () => {
  const list = proxy.$setJsonProxy(state.list);
  if (form.carrierCode) {
    if (nextIndex.value < list.length - 1) {
      let res = await queryList({
        cargoType: "1",
        carrierCode: form.carrierCode,
        lineCode: "1",
        loadPortCode: form.loadPortCode,
        region: "1",
        feeCode: list[nextIndex.value].feeCode,
      });
      if (res.code == 200) {
        if (res.data.length) {
          list[nextIndex.value].baseFee = res.data[0].box40gp;
          // list[nextIndex.value].currency = res.data[0].currency
          state.list = list;
        } else {
          list[nextIndex.value].baseFee = 0;
          // if (list[nextIndex.value].currency) delete list[nextIndex.value].currency
          state.list = list;
        }

        nextIndex.value++;
        getQueryList();
      }
    }
  }
};

/********watch*********/
watch(
  [form],
  ([newLoad, oldLoad]) => {
    // console.log(newLoad,oldLoad)
  },
  {}
);
/********computed*******/
let isAdd = computed(() => {
  return route.query.carrierCode ||
    route.query.clientCompanyId ||
    route.query.loadPortCode
    ? false
    : true;
});
</script>
<style>
.wrapper {
  background-color: #f7f8fa;
  padding: 10px 6px 0 6px;
  height: calc(100vh);
  box-sizing: border-box;
}
.preview-list {
  margin-top: 10px;
}
.picture {
  width: 100vw;
  position: relative;
}
.picture .tips {
  position: absolute;
  width: 80px;
  height: 20px;
  z-index: 100;
  transform: rotate(315deg);
  left: -6vw;
  top: 0;
  text-align: center;
  background-color: #13ce66;
  color: #f7f8fa;
}

.picture img {
  width: 100%;
  object-position: 0 0;
  object-fit: contain;
}
.data-collect-upload {
  margin: 8px;
  display: flex;
  justify-content: center;
}
.wrapper .data-collect-upload {
  min-width: 300px;
}
/* .choose-picture {
  width: 300px;
  height: 300px;
  background-image: url("./1.png");
  background-origin: content-box;
} */
.preview-modal {
  max-width: 800px;
}
.preview-modal img {
  max-width: 800px;
  width: 90%;
}
</style>
