<template>
   <div class="search_warpper">
        <div :class="['select',!border && 'select-defalut' ]" :style="{...fieldStyle}">
            <div v-if="label" :class="['title_box',labelClassName && labelClassName({prop,value:modelValue})]" ref="titleRef" :style="{width:labelWidth ? labelWidth + 'px' : '60px',justifyContent:justifyContent || 'start'}" ><span class="required title" v-if="rule&&rule.required" >*</span>{{label}}</div>
            <van-field
                v-model="cell_value"
                is-link
                readonly
                :placeholder="placeholder||'请选择'"
                @click="onShow"
                :class="[border ? 'field_border':'field_cell','no_title',size]"
                :border="true"
                :style="{width:`calc(100% - ${label ? labelWidth || 60 : 0}px)`}"
            >
        </van-field>
    </div>
       <span v-if="(rule && rule.required)" class="verify" :style="{opacity:rule && rule.required && verify ? 1 : 0,paddingLeft: err_padding_left + 'px',transition:'all .5s'}">{{rule && rule.message}}</span>
       <van-popup v-model:show="show" round position="bottom">
            <van-picker :columns="columns" v-model="selectedValues" @confirm="onConfirm" @cancel="onCancel" @change="onChange">
                <template #title>
                   <div class="search_box" v-if="isSearch">
                    <van-field
                        v-model="value"
                        left-icon="search"
                        placeholder="搜索关键字"
                        @input="onInput"
                        @blur="onBlur"
                        
                    />
                   </div>
                   <span v-else>{{title || ''}}</span>
                </template>
                <template #cancel>
                    <span>清空</span>
                </template>
            </van-picker>
        </van-popup>
   </div>
</template>
<script setup>
import { ref, onMounted , computed , getCurrentInstance,watch} from "vue";

const { proxy } = getCurrentInstance()

const props = defineProps({
    label:{
        type:String,
        default:''
    },
    placeholder:String,
    border:{
        type:Boolean,
        default:false
    },
    isSearch:{  //选择项-开启搜索功能
        type:Boolean,
        default:true
    },
    title:String,   //选择项的名称 - isSearch = false 时候生效
    modelValue: {
        type: String,
        default: ''
    },
    rule:{
        type:Object,
        default:()=>{
            return {
                required:false,
                message:'',
                trigger:'blur'
            }
        }
    },
    options:Array,
    size:{
        type:String,
        default:'medium'
    },
    labelWidth:Number,
    labelClassName:Function,
    prop:String,
    justifyContent:String,
    fieldStyle:{
        type:Object,
        default:()=>{return {}}
    }

})
const emits = defineEmits(['update:modelValue','change','confirm'])

const titleRef=ref()
const show = ref(false)
const cell_value=ref('')
const value = ref('')
const columns = ref([])
const selectedValues=ref([''])
const verify=ref(false)
const err_padding_left=ref(16)

onMounted(() => {
    if(titleRef.value) err_padding_left.value=titleRef.value.offsetWidth + 15;
    columns.value=proxy.$setJsonProxy(props.options)
    // if(props.modelValue){
    //     console.log(props.modelValue)
    //     cell_value.value=proxy.$setJsonProxy(props.options).find(el=>el.value == props.modelValue) ? proxy.$setJsonProxy(props.options).find(el=>el.value == props.modelValue).text : ''
    //     selectedValues.value=[props.modelValue]
    // }
})
const onShow=()=>{
    search_fun()
    show.value=true
}
const onCancel=()=>{
    cell_value.value = '';
    value.value=''
    show.value=false
    emits('update:modelValue','')
    validate()
}
const onConfirm = ({ selectedValues,selectedOptions }) => {
    cell_value.value = selectedOptions[0].text;
    value.value=''
    show.value=false
    emits('update:modelValue',selectedValues.join(','))
    emits('confirm',selectedValues.join(','))
    validate()
};

const onChange = ({ selectedValues }) => {
    emits('change',selectedValues.join(','))
};

const onInput=()=>{
    search_fun()
}
const onBlur=()=>{
    search_fun()
}

const search_fun=()=>{
    if(!value.value || value.value == ''){
         columns.value=proxy.$setJsonProxy(props.options)
   }else{
        throttle(()=>{
            console.log(value.value)
            columns.value=proxy.$setJsonProxy(props.options).filter(item=>item.text.indexOf(value.value) != -1 || item.value.toLowerCase().indexOf(value.value.toLowerCase()) != -1)
        },500)
   }
}

let timer=null
const throttle=(fun,duration)=>{
    clearInterval(timer)
    timer=setTimeout(() => {
        fun()
    },duration);
}

const validate=()=>{
    let status = true
    if(cell_value.value){
        verify.value=false ;
    }else{
        verify.value=true ;
        status = false
    }
    return status
}
//computed

defineExpose({
    validate,
    verify
})

watch(props,(newVal,oldVal)=>{
    if(newVal.modelValue){
        cell_value.value=proxy.$setJsonProxy(props.options).find(el=>el.value == props.modelValue) ? proxy.$setJsonProxy(props.options).find(el=>el.value == props.modelValue).text : ''
        selectedValues.value=[props.modelValue]
    }else{
        cell_value.value=''
        selectedValues.value=['']
    }
   
})
</script>
<style scoped>
.select_inp {
    width: 100%;
    line-height: 26px;
    height: 26px;
    border-bottom: 1px solid #ededed;
    position: relative;
}

.select_inp .icon_inp {
    position: absolute;
    right: 0;
    top: 0;
}
.search_box{
    background-color: #ededed;
    border-radius: 999px;
}
.search_box>>>.van-cell{
    background-color: rgba(0,0,0,0);
    padding:4px 8px;
    width: 100%;
    

}
.required{
    color: red;
    font-size: 12px;
}
.verify{
    font-size: 12px;
    color:red;
    line-height: 12px;
    padding-left: 120px;
    display:block;
    box-sizing: border-box;
    padding-top: 3px;
}
.search_warpper{
    padding: 0;
}
.search_warpper>>>.van-cell{
    padding-left: 8px;
    padding-right: 8px;
}
.search_warpper>>>.van-cell::after{
    border: none;
}
.search_warpper>>>.field_cell::before {
   border: none;
}
.search_warpper>>>.field_border{
    border: 1px solid #F5F6F7;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
}
.search_warpper>>>.no_title .van-field__label{
    display: none;
}
.search_warpper>>>.medium{
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.search_warpper>>>.small{
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 26px;
}
.search_warpper>>>.small .van-icon{
    line-height: 26px;
}
.search_warpper>>>.mini{
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
    height: 22px;
    box-sizing: border-box;
}
.search_warpper>>>.mini .van-icon{
    line-height: 20px;
    height: 22px;
    font-size: 12px;
}
.search_warpper>>>.title_box{
    display: flex;
    margin-right: 8px;
    font-size: 13px;
    color: #333;
}
.select{
    display: flex;
    align-items: center;
    padding:0;
    position: relative;
}
/* .select-defalut::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
    height: 1px;
    border-bottom:1px solid #F5F6F7;
   
} */
.select-defalut>>>.van-cell{
    border-bottom:1px solid #F5F6F7;
}
</style>