import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import Cookies from 'js-cookie'
import { removeToken, setToken } from '@/utils/auth'

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
  //判断是否有标题
  if (to.meta.title) {
    //判断新增还是编辑
    if(to.meta.title == '客户报价'){  
      if(Object.keys(to.query).length){
        document.title = '客户报价编辑'
      }else{
        document.title =  '客户报价新增'
      }
    }else if(to.path == '/'){
      document.title = '客户报价维护'
    }else if(to.path == '/'){
      document.title = '待确认海运费'
    }else{
      document.title=to.meta.title
    }
  } 
  if (to.query.access_token) {
    Cookies.remove('access-token')
    removeToken()
    setToken(to.query.access_token)
    next()
  }else{
    next()
  }

})

export default router;