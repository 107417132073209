<template>
    <router-view v-slot="{ Component }">
      <transition name="fade" enter-active-class="animate__animated animate__fadeIn animate__fast">
         <component :is="Component"></component>
      </transition>
    </router-view>
</template>

<script>
import equipment from '@/store/equipment'
import { mapActions } from 'pinia';
export default {
  name: 'App',
  mounted(){
    this.setAppHeight(document.documentElement.clientHeight)
  },
  methods:{
    ...mapActions(equipment,['setAppHeight'])
  }
}
</script>

<style>
*{
  margin: 0;
  padding:0;
}
ui,li{
  list-style: none;
}
.el-select-dropdown__item {
    padding:0 6px;
}
</style>
