import axios from 'axios'
import { getToken,removeToken } from '@/utils/auth'
import { ElNotification,ElMessage } from 'element-plus'
import { showNotify } from 'vant';
import Cookies from 'js-cookie'
import errorCode from '@/utils/errorCode'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers['X-system-type']='1'
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
    // console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
       // 获取错误信息
       const msg = errorCode[code] || res.data.msg || errorCode['default']

    if (code === 401) {
      showNotify({ type: 'warning', message: '登录状态已过期!' });
      Cookies.remove('access-token')
      removeToken()
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      showNotify({ type: 'warning', message: msg });
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      showNotify({ type: 'danger', message: msg });
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
