<template>
    <div class="search_warpper">
         <van-field
             v-model="cell_value"
             is-link
             readonly
             :placeholder="placeholder||'请选择日期'"
             @click="onShow"
             :class="[border ? 'field_border':'field_cell',!label && 'no_title',size]"
         >
             <template #label>
                 <span class="required" v-if="rule&&rule.required">*</span>{{label}}
             </template>
        </van-field>
        <span class="verify" :style="{opacity:rule && rule.required && verify ? 1 : 0}">{{rule && rule.message}}</span>
        <van-popup v-model:show="show" round position="bottom">
            <van-date-picker
                v-model="currentDate"
                title="选择日期"
                :min-date="minDate || min_date"
                :max-date="maxDate || max_date"
                :columns-type="columnsType"
                @confirm="onConfirm" 
                @cancel="onCancel"
                />
         </van-popup>
    </div>
 </template>
 <script setup>
 import { ref, onMounted , computed , getCurrentInstance} from "vue";
 
 const { proxy } = getCurrentInstance()
 
 const props = defineProps({
     label:{
         type:String,
         default:''
     },
     placeholder:String,
     border:{
        type:Boolean,
        default:true
    },
     modelValue:{
        type:Array
     },
     rule:{
         type:Object,
         default:()=>{
             return {
                 required:false,
                 message:'',
                 trigger:'blur'
             }
         }
     },
     columnsType:{
        type:Array,
        default:()=>{
            return ['year', 'month','day']
        }
     },
    minDate:{
        type:Date
    },
    maxDate:{
        type:Date
    },
    size:{
        type:String,
        default:'mini'
    }
 })
 const emits = defineEmits(['update:modelValue'])
 
 const show = ref(false)
 const cell_value=ref('')
 const currentDate=ref([])
 const verify=ref(false)
 
 onMounted(() => {
     if(props.modelValue.length){
        cell_value.value=proxy.$setJsonProxy(props.modelValue.join('-'))
        currentDate.value=proxy.$setJsonProxy(props.modelValue)
     }
 })
 const onShow=()=>{
     show.value=true
 }

 const onCancel=()=>{
     cell_value.value = '';
     show.value=false
     emits('update:modelValue',[])
     validate()
 }

 const onConfirm = ({ selectedValues }) => {
     cell_value.value = selectedValues.join('-');
     show.value=false
     emits('update:modelValue',selectedValues)
     validate()
 };
 
 const validate=()=>{
     let status = true
     if(cell_value.value){
         verify.value=false ;
     }else{
         verify.value=true ;
         status = false
     }
     return status
 }
//computed
const year=computed(()=>{
    return new Date().getFullYear()
})
const min_date=computed(()=>{ 
    return new Date(year.value - 5,0,1)
})
const max_date=computed(()=>{ 
    return new Date(year.value + 5,11,31)
})

 defineExpose({
     validate,
     verify
 })
 </script>
 <style scoped>
 .select_inp {
     width: 100%;
     line-height: 26px;
     height: 26px;
     border-bottom: 1px solid #ededed;
     position: relative;
 }
 
 .select_inp .icon_inp {
     position: absolute;
     right: 0;
     top: 0;
 }
 .search_box{
     background-color: #ededed;
     border-radius: 999px;
 }
 .search_box>>>.van-cell{
     background-color: rgba(0,0,0,0);
     padding:4px 8px;
     width: 100%;
     
 
 }
 .required{
     color: red;
     font-size: 12px;
 }
 .verify{
     font-size: 12px;
     color:red;
     line-height: 12px;
     padding-left: 120px;
     display:block;
     box-sizing: border-box;
     padding-top: 3px;
 }
 .search_warpper>>>.van-cell::after{
     border: none;
 }
 .search_warpper>>>.field_cell{
     position: relative;
 }
 .search_warpper>>>.field_cell::before {
     position: absolute;
     box-sizing: border-box;
     content: " ";
     pointer-events: none;
     right: var(--van-padding-md);
     bottom: 0;
     left: var(--van-padding-md);
     border-bottom: 1px solid #F5F6F7;
 }
 .search_warpper>>>.field_border{
    border: 1px solid #F5F6F7;
    border-radius: 5px;
    margin: 0 16px;
    box-sizing: border-box;
    padding-left: 14px;
    padding-right: 14px;
    width: calc(100% - 32px);
}
.search_warpper>>>.no_title .van-field__label{
    display: none;
}
.search_warpper>>>.medium{
    padding-top: 10px;
    padding-bottom: 10px;
}
.search_warpper>>>.small{
    padding-top: 6px;
    padding-bottom: 6px;
}
.search_warpper>>>.mini{
    padding-top: 2px;
    padding-bottom: 2px;
}
 </style>