<template>
    <div>
        <!-- <van-nav-bar title="客户报价管理" /> -->
        <div class="search_wrapper">
            <div class="search_box">
                <div class="search_inp">
                    <van-search v-model="value" show-action label="" placeholder="请输入搜索关键词" @search="onSearch"
                        @clear="onClear" @input="inputChenge" @focus="inputFocus">
                        <template #action>
                            <van-button size="small" round type="primary" @click="onClickButton" class="search_btn">搜索
                            </van-button>
                        </template>
                    </van-search>
                </div>
            </div>
            <div class="search_res" v-if="state.visible">
                <ul v-if="(state.search_list && state.search_list.length && searchVisible)">
                    <li v-for="(el, index) in state.search_list" :key="index" @click.stop="handleItem(el)">{{
                            el.clientCompanyName
                    }}</li>
                </ul>
                <div class="error" v-else-if="(!state.search_list || !state.search_list.length) && searchVisible">
                    <van-empty description="暂无搜索结果！" image-size="4rem" />
                </div>
            </div>
        </div>
        <div class="phone_table">
            <ul class="table_head">
                <li v-for="(el, index) in state.heads" :key="index">{{ el.label }}</li>
            </ul>

            <div class="table_body" v-if="state.list.length">
                <!-- <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
                <div v-for="(item, idx) in state.list" :key="idx" class="all_li" @click="rowClick(item)">
                    <div v-for="(el, index) in state.heads" :key="index"
                        :class="[el.prop == 'carrierName' && 'link', 'item_text']">
                        {{ el.prop == 'isExternal' ? item[el.prop] ? '外配' : '内配' :el.prop == 'lineName'?item[el.prop] == ''?'全部':item[el.prop] : item[el.prop]}}
                    </div>
                </div>
                <!-- </van-list> -->
            </div>
            <div class="table_body" v-else>
                <van-empty description="暂无数据！" />
            </div>
        </div>
        <div class="btn_box">
            <van-button icon="plus" type="primary" block @click="handleNewly">新增报价</van-button>
        </div>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import equipment from '@/store/equipment'   //store
import { showToast } from 'vant';
import { listByClient, searchClient } from '@/api/index'

const { proxy } = getCurrentInstance()

const { getAppHeight } = equipment()


const router = useRouter() // 新增
const route = useRoute()
const value = ref('');
const searchVisible = ref(false)
const clientCompanyId = ref(null)
const state = reactive({
    timer: null,
    visible: false,
    search_list: [],
    list: [],
    heads: [
        {
            label: '委托单位',
            prop: 'clientCompanyName'
        },
        {
            label: '起运港',
            prop: 'loadPortName'
        },
        {
            label: '船司',
            prop: 'carrierName'
        },
        {
            label: '航线',
            prop: 'lineName'
        },
        {
            label: '是否外配',
            prop: 'isExternal'
        },
    ]
})
//mounted
onMounted(() => {
    proxy.$dd.biz.navigation.setTitle({
        title: "客户报价维护",
      });
    getData()
    document.addEventListener(
        "click",
        (e) => {
            let port = document.querySelector('.search_wrapper');
            if (port && !port.contains(e.target)) {
                state.visible = false;
            }
        },
        true
    );
})
//methods
const onSearch = (val) => showToast(val);

//点击清除按钮后触发
const onClear = () => {
    if (!state.visible) state.visible = true
    getSearchData()
}
//聚焦
const inputFocus = () => {
    if (!state.visible) state.visible = true
    getSearchData()
}

//模糊搜索结果-点击
const handleItem = (v) => {
    value.value = v.clientCompanyName;
    clientCompanyId.value = v.clientCompanyId;
    state.visible = false;
}
//模糊查询
const inputChenge = (v) => {
    clientCompanyId.value=''
    if (!state.visible) state.visible = true
    clearTimeout(state.timer);
    state.timer = setTimeout(() => {
        // 执行搜索请求
        getSearchData()
    }, 400); // 设置时间
}

//模糊搜索-数据 
const getSearchData = async () => {
    let res = await searchClient({ key: value.value })
    if (res.code == 200) {
        searchVisible.value = true
        state.search_list = res.data
    }
}

//getData
const getData = async () => {
    const res = await listByClient(clientCompanyId.value || 0, {
        pageSize: 1000,
        pageNum: 1
    })
    if (res.code == 200) {
        state.list = res.rows
    }
}

//搜索按钮
const onClickButton = () => {
    getData()
};

//新增
const handleNewly = () => {
    router.push({
        path: '/newly'
    })
}
//详情｜编辑
const rowClick = (row, column, event) => {
    const { carrierCode, clientCompanyId, loadPortCode,isExternal,lineCode } = proxy.$setJsonProxy(row)
    router.push({
        path: '/newly',
        query: {
            carrierCode,
            clientCompanyId,
            loadPortCode,
            isExternal,
            lineCode

        }
    })
}
//watch
watch(value, (newval, oldval) => {
})
</script>
<style scoped>
.search_wrapper {
    position: relative;
}

.search_box {
    border-radius: 999px;
    overflow: hidden;
    margin: 10px 12px;
    border: 1px solid #1989fa;
    padding: 1px 2px 1px 1px;
    background-color: #fff;

}

.search_inp {
    background-color: #F7F8FA;
}

.search_box>>>.van-search {
    padding: 0;
    background: none;
}

.search_box>>>.van-search__action {
    padding: 0;
    display: flex;
    width: 17%;
    padding-left: 8px;
}

.search_btn {
    border: none;
    padding: 0 10px;
    width: 100%;
}

.search_btn>>>.van-button {
    padding: 0 20px;
}

.search_res {
    position: absolute;
    top: 40px;
    left: 0;
    width: calc(100% - 40px);
    color: #666;
    margin: 0 20px;
    box-sizing: border-box;
    z-index: 99;
}

.search_res ul {
    /* max-height: 40vh; */
    min-height: auto;
    background-color: #fff;
    box-shadow: 0 4px 6px #ddd;
    border-radius: 5px;
    height: auto;
    overflow-y: auto;
}

.search_res ul li {
    line-height: 26px;
    border-bottom: 1px solid #eee;
    padding: 5px 12px;
}

.search_res .error {
    height: 10vh;
    background-color: #fff;
    box-shadow: 0 4px 6px #ddd;
    border-radius: 5px;
    height: auto;
    overflow-y: auto;
}

/* table */
.phone_table {}

.phone_table>>>.el-table__cell {
    padding: 8px 0;
}

.phone_table>>>.cell {
    padding: 0 2px;
    font-size: 13px;
}

.link {
    color: #1989fa;
}



.table_head {
    padding: 0 12px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ededed;
}

.table_head li {
    padding: 0 3px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 700;
    color: #888;
    line-height: 40px;
    flex: 1;
    text-align: center;

}

.table_body {
    padding: 0 12px;
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 160px);
    overflow-y: auto;
}

.all_li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    color: #606166;
    border-bottom: 1px solid #dcdfe6;
}

.item_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    width: 100%;
    padding: 6px 3px;
    line-height: 26px;
    text-align: center;
}

/* btn */
.btn_box {
    padding: 0 12px;
    padding-top: 10px;
}
</style>