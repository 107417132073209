<template>
    <div>
        <van-row v-if="Object.keys(forms).length" :gutter="(gutter || 3)">
             <van-col v-for="(el,index) in models" :key="index" :span="el.span || 24">
                <template v-if="(!el.type || el.type == 'select')">
                    <my-picker v-if="(el.rule && el.rule.required)" v-model="forms[el.prop]" v-bind="{...el,labelWidth,labelClassName,justifyContent,fieldStyle}" :ref="el.rule && el.rule.required && refs[index]"/>
                    <my-picker v-else v-model="forms[el.prop]" v-bind="{...el,labelWidth,labelClassName,justifyContent}"/>
                </template>
                <template v-else-if="el.type == 'date'">
                    <my-date-picker v-model="forms[el.prop]"/>
                </template>
            </van-col>
        </van-row>
    </div>
</template>
<script setup>
import {ref,reactive,onMounted,watch} from 'vue'

const props=defineProps({
    models:{
        type:Array,
        defalut:()=>{
            return []
        }
    },
    labelWidth:Number,
    justifyContent:String,
    labelClassName:Function,
    gutter:Number | String,
})
const emits=defineEmits(['onUpdate'])

const forms=reactive({})
const refs=reactive([])

onMounted(()=>{
    props.models.forEach(el=>{
        if(!el.type || el.type == 'select' ){
            forms[el.prop]=el.defaultValue || ''
        }else if(el.type == 'date'){
            forms[el.prop]=el.defaultValue || []
        }
    })
   setTimeout(() => {
    props.models.forEach((el,index) => {
        if(el.rule && el.rule.required){
            refs.push(ref())   
        }else{
            refs.push('no-ref')
        }
    });
   }, 100);
})

const validates= ()=>{
    console.log(refs)
    return new Promise((reslove,reject)=>{
        let res= refs.filter(v=>v != 'no-ref').filter((el)=>{
             return !el.value[0].validate() 
        })
        if(res.length){
            reject('error')
        }else{
            reslove('success')
        }
    })
}
//watch 
watch([forms],([newVal,oldVal])=>{
    emits('onUpdate',newVal)
})


defineExpose({
    validates
})
</script>
<style scoped>

</style>