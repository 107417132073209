import {defineStore} from 'pinia'

const useEquipment=defineStore('equipment',{
    state:()=>{
        return {
            height:0
        }
    },
    getters:{
        getAppHeight(){
            return this.height
        }
    },
    actions:{
        setAppHeight(v){
            console.log(v)
            this.height=v
        }
    }
})

export default useEquipment;