import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from '@/router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 1. 引入你需要的组件
import {
    Button, NavBar, Search, Icon, Empty, Checkbox, CheckboxGroup, List, Loading, Tag, Field, Picker, Popup, Cell, Col, Row, DatePicker,
    Uploader
} from 'vant';
import 'vant/lib/index.css';

import 'animate.css'

import * as dd from 'dingtalk-jsapi';

import App from './App.vue'

//自定义组件
import picker from '@/components/picker'
import pickerGroup from '@/components/pickerGroup'
import datePicker from '@/components/datePicker'

import setJsonProxy from '@/utils/jsonProxy'

const app = createApp(App)

//全局挂在方法
app.config.globalProperties.$setJsonProxy = setJsonProxy
app.config.globalProperties.$dd = dd

app.component('my-picker', picker)
app.component('my-picker-group', pickerGroup)
app.component('my-date-picker', datePicker)

app.use(Button)
app.use(NavBar)
app.use(Search)
app.use(Icon)
app.use(Empty)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(List)
app.use(Loading)
app.use(Tag)
app.use(Field)
app.use(Picker)
app.use(Popup)
app.use(Cell)
app.use(Col)
app.use(Row)
app.use(DatePicker)
app.use(Uploader);

app.use(router)
app.use(createPinia())
app.use(ElementPlus)
app.mount('#app')
