import home from '@/views/home'
/*import newly from '@/views/newly'
import oceanFreight from '@/views/oceanFreight'
import salesDetail from '@/views/salesDetail'
import interline from '@/views/interline'*/
import ddEntrance from '@/views/ddEntrance'
import test from '@/views/test'
import dataCollection from '@/views/dataCollection'

const routes = [
    {
        path: "/",
        component: home,
        meta: {
            title: '客户报价维护'
        }
    },
    /*{
        path:"/newly",
        component:newly,
        meta:{
            title:'客户报价'
        }
    },
    {
        path:"/oceanFreight",
        component:oceanFreight,
        meta:{
            title:'待确认海运费'
        }
    },
    {
        path:"/salesDetail",
        component:salesDetail,
        meta:{
            title:'客户海运费确认'
        }
    },
    {
        path:"/interline",
        component:interline
    },*/
    {
        path: "/ddEntrance",
        component: ddEntrance
    },
    {
        path: "/test",
        component: test
    },
    {
        path: "/dataCollection",
        component: dataCollection
    },
]

export default routes;