import request from '@/utils/request'
import qs from 'query-str'

//起运港
export const getPortList = (data) => {
  return request({
    url: '/operation/base/port/list',
    method: 'POST',
    data
  })
}

export const getPicture = (data) => {
  return request({
    url: '/smart/face/avatar',
    method: 'GET',
    data
  })
}

//船司
export const getCarrierList = (data) => {
  return request({
    url: '/operation/base/carrier/list', // /operation   /ship
    method: 'POST',
    data,
  })
}

//组货企业下拉列表
export const getAllList = () => {
  return request({
    url: '/operation/company/allList',
    method: 'GET',
  })
}


//组货企业下拉列表
export const searchClient = (params) => {
  return request({
    url: '/ship/custom/fee/searchClient',
    method: 'GET',
    params
  })
}



//查询订舱客户费用列表
export const listByClient = (clientCompanyId, query) => {
  let url = query ? qs.stringify(query, `/ship/custom/fee/listByClient/${clientCompanyId}`) : `/ship/custom/fee/listByClient/${clientCompanyId}`
  return request({
    url,
    method: 'GET'
  })
}



//费用项下拉框接口
export const baseItemList = (query) => {
  return request({
    url: qs.stringify(query, '/ship/base/item/list'),
    method: 'GET',
  })
}


//新增-编辑客户报价
export const saveOrUpdate = (data) => {
  return request({
    url: '/ship/custom/fee/saveOrUpdate',
    method: 'POST',
    data
  })
}

//客户报价详情
export const getInfo = (data) => {
  return request({
    url: '/ship/custom/fee/getInfo',
    method: 'POST',
    data
  })
}



//基准价格查询接口
export const queryList = (params) => {
  return request({
    url: '/ship/base/fee/queryList',
    method: 'GET',
    params
  })
}

//订单报价列表
export const listByFeeCode = (data, query) => {
  const url = query ? qs.stringify(query, '/ship/fee/cdoc/listByFeeCode') : '/ship/fee/cdoc/listByFeeCode'
  return request({
    url,
    method: 'POST',
    data: data
  })
}


//销售列表展示字段
export const saleListFiled = (data) => {
  return request({
    url: '/ship/fee/cdoc/saleListFiled',
    method: 'POST',
    data: data
  })
}


//查询费用明细
export const feeDetail = (data) => {
  return request({
    url: '/ship/fee/order/feeDetail',
    method: 'POST',
    data: data
  })
}


//确认价格
export const confirmfee = (data) => {
  return request({
    url: '/ship/fee/order/confirmfee',
    method: 'POST',
    data: data
  })
}



//起运港
export const loadPort = () => {
  return request({
    url: '/ship/common/loadPort',
    method: 'GET'
  })
}

//获取航线代码下拉列表数据
export const regionLine = (line) => {
  return request({
    url: `/ship/common/regionLine/${line}`,
    method: 'GET'
  })
}