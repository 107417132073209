<template>
    <div style="width: 100%;height: 100vh;display: flex;align-items: center;justify-content: center;" >
        <van-loading type="spinner" />
    </div>
</template>
<script setup>
import { ref,onMounted } from 'vue'
import { useRoute } from 'vue-router'
import Cookies from 'js-cookie'
import * as dd from 'dingtalk-jsapi';

import { removeToken } from '@/utils/auth'

const route=useRoute()

onMounted(() => {
    init()
})
const init = () => {
    Cookies.remove('access-token')
    removeToken()
    dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
            corpId: 'dingfe7f0f8400f595ecf2c783f7214b6d69', //三方企业ID
            onSuccess: function (result) {
                let url=route.query.url
                location.href = `${process.env.VUE_APP_HREF}?authCode=${result.code}&url=${url}`
            },
            onFail: function (err) {
                alert(JSON.stringify(err))
            }
        });
    })
}
</script>