<template>
    <div>
        <div class="wrapper">
            <div class="my_form">
                <my-picker-group :models="models" @onUpdate="onUpdate" ref="modelRef" :label-width="65" justify-content="end" :label-class-name="labelClassName" v-if="isAdd"/>
                <!-- 编辑 -->
                <el-form :label-width="67" v-else>
                    <el-row>
                        <el-col :span="17">
                            <div class="detail_info_box">
                                <img src="@/assets/gongsi.png" width="18" />
                                <span class="detail_span">{{ formDetail.clientCompanyName}}</span>
                            </div>
                        </el-col>
                        <el-col :span="7">
                            <div class="detail_info_box">
                                <img src="@/assets/zhuyaogangkou.png" width="18" />
                                <span class="detail_span">{{ formDetail.loadPortName }}</span>
                            </div>
                        </el-col>
                        <el-col :span="17">
                            <div class="detail_info_box">
                                <img src="@/assets/chuan.png" width="18" />
                                <span class="detail_span">{{ formDetail.carrierName }}</span>
                            </div>
                        </el-col>
                        <el-col :span="7">
                            <div class="detail_info_box">
                                <img src="@/assets/peizhiguanli.png" width="18" />
                                <span class="detail_span">{{ formDetail.isExternal ? '外配' : '内配' }}</span>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="my_table">
                <div class="my_table_head">
                    <van-row style="text-align:center;">
                        <van-col span="8">
                            <label  class="my_cell">费用项</label>    
                        </van-col>
                        <van-col span="8">
                            <label  class="my_cell">基准价格</label>
                        </van-col>
                        <van-col span="5">
                            <label  class="my_cell">最终金额</label>
                        </van-col>
                        <van-col span="3">
                            <label  class="my_cell" style="opacity:0;">操作</label>
                        </van-col>
                    </van-row>
                </div>
                <div class="my_table_body" :style="{height:isAdd ? `${getAppHeight - 280}px` : `${getAppHeight - 200}px`,overflowY:'auto'}">
                    <van-row class="body_line" style="text-align:center;" v-for="(row,$index) in isAdd ? [].concat(state.baseFeeList,state.list) : state.list" :key="row">
                        <van-col span="8">
                           <div class="body_cell">
                            <my-picker v-if="row.new" v-model="row.feeCode" :options="state.baseItemListOptions" border size="mini" @confirm="handleFee(row.feeCode,$index)"/>
                            <span style="font-size:12px;display:block;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;color: #606266;" v-else>{{ filerFeeName(row.feeCode) }}{{row.currency && filterUnitName(row.pieceUnit) ? `(${row.currency +'/'+ filterUnitName(row.pieceUnit)})`:row.currency?`(${row.currency})`:`(${filterUnitName(row.pieceUnit)})`}}</span>
                           </div>
                        </van-col>
                        <van-col span="8">
                            <div class="body_cell center">
                            <span style="color: #606266;">{{ (row.baseFee == 0 ? '-' : row.baseFee) }}</span>
                         </div>
                        </van-col>
                        <van-col span="5">
                            <div class="body_cell center">
                            <el-input-number v-model="row.adjustFee" placeholder="请输入" size="small" :min="0"
                                :class="['input_num', row.adjustFee < 0 ? 'inp_red' : '']" :controls="false"
                                style="width:60px"></el-input-number>
                                </div>
                        </van-col>
                        <van-col span="3">
                            <div class="body_cell center">
                            <van-button icon="plus" type="primary" round size="small" v-if="row.new"
                                :disabled="!row.feeCode" @click="addTableItem(row.feeCode)" />
                            <van-button icon="minus" type="danger" round size="small" v-else
                                @click="delTableItem(row)" />
                                </div>
                        </van-col>
                    </van-row>
                </div>
            </div>
            <div class="btn">
                <van-button type="primary" block @click="submit">保存</van-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, getCurrentInstance, watch, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { showNotify } from 'vant';
import equipment from '@/store/equipment'

import getUuid from '@/utils/uuid'
import { getAllList, getPortList, getCarrierList, baseItemList, saveOrUpdate, getInfo, queryList,loadPort } from '@/api/index'
import { listData } from '@/api/enum'

const modelRef=ref()

const { proxy,ctx } = getCurrentInstance()

const tablItemNew = { feeCode: '', baseFee: 0, adjustFee: 0, new: true }

const { getAppHeight } = equipment()

const router = useRouter()
const route = useRoute()

/*********state*******/
const nextIndex = ref(0)
const companys = ref([])
const carrierCodeOptions = ref([])
const loadPortOptions = ref([])
const isExternalOptions = ref([
    {
        text: '内配',
        value: '0'
    },
    {
        text: '外配',
        value: '1'
    },
])
const state = reactive({
    tableList:[],
    baseFeeList:[],
    list: [],
    copyList:[],
    baseItemListOptions: [],
    unitOptions: [],
    id: undefined
})
let form = reactive({
    clientCompanyId: '',
    loadPortCode: '',
    isExternal: '',
    carrierCode: ''
})
let formDetail = reactive({
    clientCompanyName: '',
    loadPortName: '',
    isExternal: '',
    carrierName: ''
})

/*********mounted*********/
onMounted(async () => {
    proxy.$dd.biz.navigation.setTitle({
        title: `客户报价${isAdd ? '新增' : '编辑'}`,
      });
    //初始化 table 新增最底下一行
    state.list.push({ ...tablItemNew, uuid: getUuid() })
     getCompany()
     getBaseCarrieFun()
     getPortListFun()
     getBaseItemListFun()
     getEnum('piece_unit', 'unitOptions')

    if (Object.keys(route.query).length) {
        getData()
    }
})

/*******methods*********/

//编辑回显
const getData = async () => {
    let res = await getInfo({
        carrierCode: route.query.carrierCode,
        clientCompanyId: route.query.clientCompanyId,
        loadPortCode: route.query.loadPortCode,
        isExternal:route.query.isExternal

    })
    if (res.code == 200) {
        const { clientCompanyId, loadPortCode, isExternal, carrierCode, shipCustomFees, id ,clientCompanyName,loadPortName,carrierName} = res.data
        state.id = id
        formDetail.carrierName=carrierName
        formDetail.loadPortName=loadPortName
        formDetail.clientCompanyName=clientCompanyName
        formDetail.isExternal=isExternal

        form = {
            clientCompanyId,
            loadPortCode,
            isExternal,
            carrierCode
        }


        let list = [].concat(shipCustomFees.map(el => ({ ...el, uuid: getUuid() })), [{ ...tablItemNew, uuid: getUuid() }])
        state.list = list
    }
    
}

const onUpdate=(row)=>{
    form=proxy.$setJsonProxy(row)
    if(row.loadPortCode && row.carrierCode){
        nextIndex.value = 0;
        getQueryList();
        getBaseFeeDatas()
    }
}

const handleFee=(val)=>{
     //费用项是否重复
    let {baseFeeList,list}=state;
    let len=proxy.$setJsonProxy(list).length
    let index=list.findIndex(v=>v.feeCode == val)
    let addFeeVal=proxy.$setJsonProxy(list)[len - 1].feeCode
    let isRepetition=[].concat(baseFeeList,proxy.$setJsonProxy(list).slice(0,len - 1)).filter(el=>el.delFlag != '2').find(el=>el.feeCode == addFeeVal)
    if(isRepetition){
        ctx.$nextTick(()=>{
             state.list[len - 1].feeCode=''    
             ctx.$forceUpdate()
        })
        return  showNotify({ type: 'warning', message: '费用项目重复！' });
    }else{
        let res=proxy.$setJsonProxy(state.baseItemListOptions).find(el => el.value == val)
         state.list[index]=Object.assign(state.list[index],res || {})
        console.log(state.list)
    }
}
const addTableItem = (val) => {
    let copy_list = [...state.list].map(el => {
        delete el.new
        return {
            ...el
        }
    })
    state.list = [].concat(copy_list, [{ ...tablItemNew, uuid: getUuid() }])
    let index=state.list.findIndex(v=>v.feeCode == val)
    nextIndex.value = index;
    getQueryList();
}
//删除
const delTableItem = (row) => {
    if(isAdd.value){
        if(state.list.findIndex(el => el.uuid == row.uuid) > -1){
        let del_idx = state.list.findIndex(el => el.uuid == row.uuid);
        state.list.splice(del_idx, 1)
    }else if(state.baseFeeList.findIndex(el => el.uuid == row.uuid) > -1){
        let del_idx=state.baseFeeList.findIndex(el => el.uuid == row.uuid) > -1
        state.baseFeeList.splice(del_idx, 1)
    }
    }else{
        let new_list=proxy.$setJsonProxy(state.list)
        let del_idx = new_list.findIndex(el => el.uuid == row.uuid);
         state.list.splice(del_idx, 1)
     
    }
}

//保存
const submit = () => {
    if (isAdd.value) {
        modelRef.value.validates().then(res=>{
            // console.log(res)
            submitFun()
        }).catch(err=>{
            console.log(err)
        })
    } else {
        submitFun()
    }
}

const submitFun = async () => {
    const jsonCompanys = proxy.$setJsonProxy(companys.value).find(item => item.value == form.clientCompanyId)
    const jsonCarrierCodeOptions = proxy.$setJsonProxy(carrierCodeOptions.value).find(item => item.value == form.carrierCode)
    const jsonLoadPortOptions = proxy.$setJsonProxy(loadPortOptions.value).find(item => item.value == form.loadPortCode)
    let shipCustomFees;
    if(isAdd.value){
        shipCustomFees = [].concat(proxy.$setJsonProxy(state.baseFeeList),proxy.$setJsonProxy(state.list)).filter(el => el.feeCode).map(item => {
            if(item.new) delete item.new
            return {
                adjustFee: item.adjustFee,
                baseFee: item.baseFee,
                feeCode: item.feeCode,
                feeName: filerFeeName(item.feeCode),
                currency: item.currency || undefined,
                id: item.id || undefined,
                pieceUnit:item.pieceUnit || undefined

            }
        })
    }else{
        shipCustomFees = proxy.$setJsonProxy(state.list).filter(el => el.feeCode).map(item => {
            if(item.new) delete item.new
            return {
               ...item,
               feeName: filerFeeName(item.feeCode),
            }
        })
    }
     
    let param = {
        carrierCode: form.carrierCode,
        carrierName: jsonCarrierCodeOptions ? jsonCarrierCodeOptions.text : '',
        clientCompanyId: form.clientCompanyId,
        clientCompanyName: jsonCompanys ? jsonCompanys.text : '',
        isExternal: form.isExternal == 1 ? true : false,
        loadPortCode: form.loadPortCode,
        loadPortName: jsonLoadPortOptions ? jsonLoadPortOptions.text : '',
        shipCustomFees,
    }
    if (state.id) {
        param.id = state.id
    }

    console.log(param)
    // const res = await saveOrUpdate(param)
    // if (res.code == 200) {
    //     // 成功通知
    //     showNotify({ type: 'success', message: isAdd.value ? '新增成功！':'编辑成功！' });
    //     setTimeout(() => {
    //         router.back(-1);
    //     }, 1000);
    // }
}

//get datas
//获取委托单位数据
const getCompany = async () => {
    const res = await getAllList()
    companys.value = res.map(el => ({ text: el.companyName, value: el.id }))
}
//获取船司-下拉数据
const getBaseCarrieFun = async () => {
    try {
        let res = await getCarrierList({})
        if (res.code == 200) {
            carrierCodeOptions.value = [].concat([{ text: '全部', value: '' }], res.data.rows.map((el) => ({ text: el.code, value: el.code, })));
        }
    } catch (error) {
        console.log(`船司下拉---error=------${error}`)
    }
}
//起运港
const getPortListFun = async () => {
    try {
        let res = await loadPort()
        if (res.code == 200) {
            loadPortOptions.value = res.data.map((el) => ({ value: el.code, text: el.nameCn }));
        }
    } catch (error) {
        console.log(`起运港---error=------${error}`)
    }
}
//费用列表
const getBaseItemListFun = async () => {
    try {
        let res = await baseItemList({pageNum:1,pageSize:1000})
        if (res.code == 200) {
            state.baseItemListOptions = res.rows.map((el) => ({ value: el.feeCode, text: el.feeName ,currency:el.currency,pieceUnit:el.pieceUnit}));
            // console.log(state.baseItemListOptions)
        }
    } catch (error) {
        console.log(`费用列表---error=------${error}`)
    }
}
//枚举值
const getEnum = async (dictType, key) => {
    const res = await listData({ pageNum: 1, pageSize: 50, dictType });
    state[key] = res.rows.map(el => ({ label: el.dictLabel, value: el.dictValue }));
}

//获取-已有基准价格维护数据
const getBaseFeeDatas = async () => {
   
        console.log('000000')
        let res = await queryList({
            cargoType: '1',
            carrierCode: form.carrierCode,
            lineCode: '1',
            loadPortCode: form.loadPortCode,
            region: '1'
        })
        if (res.code == 200) {
            let list = res.data.map(item => {
                return {
                    adjustFee: item.box40gp || 0,
                    baseFee: item.box40gp,
                    feeCode: item.feeCode,
                    feeName: item.feeName,
                    currency: item.currency || undefined,
                    id: undefined,
                    delFlag: '0',
                    uuid:getUuid()
                }
            })
            state.baseFeeList = list;
        }
}
//获取基准价格查询接口
const getQueryList = async () => {
    const list = proxy.$setJsonProxy(state.list)
    if (form.carrierCode) {
        if (nextIndex.value < list.length - 1) {
            let res = await queryList({
                cargoType: '1',
                carrierCode: form.carrierCode,
                lineCode: '1',
                loadPortCode: form.loadPortCode,
                region: '1',
                feeCode: list[nextIndex.value].feeCode
            })
            if (res.code == 200) {
                if (res.data.length) {
                    list[nextIndex.value].baseFee = res.data[0].box40gp
                    // list[nextIndex.value].currency = res.data[0].currency
                    state.list = list
                } else {
                    list[nextIndex.value].baseFee = 0
                    // if (list[nextIndex.value].currency) delete list[nextIndex.value].currency
                    state.list = list
                }
               
                    nextIndex.value++
                    getQueryList()
                }
         } 

    }

}
//费用项名字
const filerFeeName = (v) => {
    let res = proxy.$setJsonProxy(state.baseItemListOptions).find(el => el.value == v)
    return res ? res.text : ''
}
const filterUnitName=(v)=>{
    let res = proxy.$setJsonProxy(state.unitOptions).find(el => el.value == v)
    return res ? res.label : ''
}

/********watch*********/
watch([form], ([newLoad, oldLoad]) => {
    // console.log(newLoad,oldLoad)
}, {})
/********computed*******/
let isAdd = computed(() => {
    return route.query.carrierCode || route.query.clientCompanyId || route.query.loadPortCode ? false : true
})

const models=computed(()=>{
    return [
    {
        prop:'clientCompanyId',
        type:'select',
        label:'委托单位',
        border:true,
        size:'small',
        options:proxy.$setJsonProxy(companys.value),
        rule:{required:true,message:'请选择委托单位'}
    },
    {
        prop:'loadPortCode',
        type:'select',
        label:'起运港',
        border:true,
        span:12,
        size:'small',
        isSearch:false,
        title:'起运港',
        options:proxy.$setJsonProxy(loadPortOptions.value),
        rule:{required:true,message:'请选择起运港'}
    },
    {
        prop:'isExternal',
        type:'select',
        label:'内配/外配',
        border:true,
        span:12,
        size:'small',
        defaultValue:'0',
        options:proxy.$setJsonProxy(isExternalOptions.value),
        rule:{required:true,message:'请选择内配/外配'}
    },
    {
        prop:'carrierCode',
        type:'select',
        label:'船司',
        border:true,
        size:'small',
        options:proxy.$setJsonProxy(carrierCodeOptions.value)
    }
]
})
</script>
<style scoped>
.wrapper {
    background-color: #F7F8FA;
    padding: 10px 6px 0 6px;
    height: calc(100vh - 46px);
    box-sizing: border-box;
}

.van-nav-bar .van-icon {
    color: #333;
    font-size: 16px;
}

/* form */
.my_form {
    background-color: #fff;
    border-radius: 4px;
    padding:7px 8px;
}

.my_form>>>.el-form-item {
    margin-bottom: 12px;
}

.my_form>>>.el-form-item__label {
    font-size: 12px;
    padding-right: 4px;
}

.my_form>>>.el-input__wrapper {
    box-shadow: 0 0 0 !important;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dcdfe6;
}

.my_form>>>.el-input__inner {
    font-size: 12px;
}

.my_form>>>.el-form-item__error {
    padding-top: 0;
}

.my_table {
    margin-top: 14px;
}

.my_table>>>.cell {
    padding: 0 6px;
}
.my_table>>>.input_num .el-input__wrapper {
    padding: 0 5px;
    font-size: 12px;
}

.my_table>>>.inp_red .el-input__inner {
    color: red;
}

.my_table>>>.col_btn .cell {
    display: flex;
}

.my_table>>>.adjust_fee .el-input {
    height: 24px;
    line-height: 24px;
}

.my_table>>>.col_btn .van-button {
    width: 24px;
    height: 24px;
    padding: 0;
}

.btn {
    margin-top: 6px;
}

/*  */
.detail_info_box {
    display: flex;
    align-items: center;
    padding: 7px 4px;
}

.detail_span {
    font-size: 12px;
    color: #666;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-left: 4px;
}
/* .my_table>>>.del_item{
    display: none;
} */

.my_table_head{
    background-color: #fff;
    border-bottom: 1px solid #dcdfe6;
}
.my_cell{
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    position: relative;
    color: #909399;
    font-size: 14px;
    font-weight: 700;
    display:block;
    padding: 8px;
}
.my_table_body{
    background-color: #fff;
}
.body_line{
    border-bottom: 1px solid #dcdfe6;
}
.body_cell{
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: start;
    
}
.center{
    justify-content: center;
}
.body_cell span{
    font-size: 12px;
    line-height: 22px;
    display:block;
}
.my_table_body>>>.van-button {
    width: 24px;
    height: 24px;
    padding: 0;
}

</style>
